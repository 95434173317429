



































































































































































































































































































































































































































































































import Vue from 'vue'
import AES from 'crypto-js/aes'

import { cloneDeep } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { Accessors } from 'vue/types/options'
import { parsePaypalConfig } from '@/helpers'
import { getValidators } from '@/mixins/FormMixin'
import { Config, BackendError, Attachment, VFormRef } from '@/types'
import {
	toFormData,
	isExactSame,
	omitEmpties,
	openFilePicker,
	convertKeysToCamelCase,
	convertKeysToSnakeCase,
} from 'vuelpers'

const initialType = (v?: Config): Omit<Config, 'createdAt' | 'updatedAt'> => {
	if (v) v = cloneDeep(v)
	return {
		iConfigureId: v?.iConfigureId,
		vLogo: v?.vLogo ?? '',
		vPhoneCode: v?.vPhoneCode ?? '',
		vEmail: v?.vEmail ?? '',
		vPhone: v?.vPhone ?? '',
		vAddress: v?.vAddress ?? { address: '' },
		vDefaultLanguage: v?.vDefaultLanguage ?? '',
		vDefaultTimezone: v?.vDefaultTimezone ?? '',
		vDefaultCurrencyCode: v?.vDefaultCurrencyCode ?? 'GBP',
		vExchangeCurrencyCodes: v?.vExchangeCurrencyCodes ?? [],
		dMinimumOrderValue: v?.dMinimumOrderValue ?? 0,
		dPercentage: v?.dPercentage,
		tMessage1: v?.tMessage1 ?? '',
		tMessage2: v?.tMessage2 ?? '',
		tMessage3: v?.tMessage3 ?? '',
		tMessage4: v?.tMessage4 ?? '',
		paypal: parsePaypalConfig(v?.paypal),
		trustedIpList: v?.trustedIpList ?? [],
		requestIp: '',
		apiInfo: v?.apiInfo
			? convertKeysToCamelCase(v.apiInfo)
			: {
					apiKey: '',
					apiSecret: '',
			  },
	}
}

export default Vue.extend({
	name: 'Settings',
	metaInfo: {
		title: 'Settings | Admin | zx55',
	},
	props: {
		categoryData: Object,
		onView: {
			type: Boolean,
			default: false,
		},
		onUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isValid: false,
			vLogo: null as Partial<Attachment> | null,
			attachmentError: false,
			rules: getValidators('required', 'email', 'password', 'ip'),
			showBuyerAttachmentError: false,
			error: {} as BackendError,
			configuration: initialType(),
			prevSettings: initialType(),
			inputIP: '',
		}
	},
	created() {
		this.onGetConfiguration()
	},
	computed: {
		...(mapGetters('configure', ['$configureSettings']) as Accessors<{
			$configureSettings: Config
		}>),
		getFormattedCurrency(): { key: string; value: any }[] {
			return (this.configuration.vExchangeCurrencyCodes || []).map(
				(currency) => ({
					key: currency.name,
					value: currency.code,
				})
			)
		},
		hasChanges(): boolean {
			return !isExactSame(this.configuration, this.prevSettings)
		},
	},
	methods: {
		...mapActions('configure', ['updateConfiguration', 'getConfiguration']),
		onGetConfiguration() {
			this.getConfiguration().then(() => {
				this.configuration = initialType(this.$configureSettings)
				this.prevSettings = initialType(this.$configureSettings)
				if (this.configuration.vLogo) {
					this.vLogo = {
						src: this.configuration.vLogo,
					}
				}
			})
		},
		onEnterIpAddress(event: any) {
			event.preventDefault()

			const formRef = this.$refs.ipFormRef as VFormRef
			if (!formRef.validate()) return

			this.configuration.trustedIpList.push(event.target.value)
			formRef.reset()
		},
		onResetUserForm() {
			this.configuration = initialType(this.$configureSettings)
		},
		async onSubmitConfig() {
			this.attachmentError = !this.vLogo || !this.vLogo.src
			if (this.attachmentError) return

			const encryptPaypal = AES.encrypt(
				JSON.stringify(this.configuration.paypal),
				process.env.VUE_APP_CRYPTO_PRIVATE_KEY || 'Key'
			).toString()

			let [err] = await this.updateConfiguration(
				toFormData(
					omitEmpties({
						...this.configuration,
						paypal: encryptPaypal,
						vLogo: this.vLogo?.file,
						vAddress: JSON.stringify(this.configuration.vAddress),
						vExchangeCurrencyCodes: JSON.stringify(
							this.configuration.vExchangeCurrencyCodes
						),
						api_info: JSON.stringify(
							convertKeysToSnakeCase(this.configuration.apiInfo)
						),
						trusted_ip_list: JSON.stringify(
							this.configuration.trustedIpList
						),
					})
				)
			)
			if (err) {
				return this.$toast.error('Error while updating settings.')
			}
			this.onGetConfiguration()
			this.$toast.success('Settings successfully updated')
		},
		onClickUploadImage() {
			openFilePicker({}, async (image: Attachment) => {
				this.vLogo = image
				this.onSubmitConfig()
			})
		},
	},
})
